<template>
  <div>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
      v-on:click.stop
    >
      <div class="kt-user-card__avatar">
        <img
          class="kt-hidden"
          alt="Pic"
          src="@/assets/media/users/default.jpg"
        />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
          >{{
            currentUser.email !== undefined
              ? currentUser.email.substring(0, 1).toUpperCase()
              : ''
          }}</span
        >
      </div>
      <div class="kt-user-card__name">{{ currentUser.email }}</div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <a href="#" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <router-link to="/profile">
            <div class="kt-notification__item-title kt-font-bold">
              My Profile
            </div>
            <div class="kt-notification__item-time">
              Account settings and more
            </div>
          </router-link>
        </div>
      </a>
      <div class="kt-notification__custom kt-space-between" v-on:click.stop>
        <a
          href="#"
          v-on:click="onLogout()"
          class="btn btn-label btn-label-brand btn-sm btn-bold"
          >Sign Out</a
        >
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from '@/store/modules/auth/actions';
import { mapGetters } from 'vuex';

export default {
  name: 'KTDropdownUser',
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        if (process.env.VUE_APP_SSO !== 'true') {
          this.$router.push({ name: 'login-from-sign' });
        }
      });
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    backgroundImage() {
      return require('@/assets/media/misc/bg-1.jpg');
    }
  }
};
</script>
